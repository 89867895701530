<template>
  <loader v-bind="{ loading }" text="Loading Users">
    <columns>
      <column>
        <page-heading
          heading="Users"
        />
        <columns>
          <column>
            <action-button :working="downloading" @click="downloadExcel" left-icon="download" class="is-small is-rounded">
              Excel
            </action-button>
          </column>
        </columns>
      </column>
      <column class="is-5">
        <form>
          <text-input 
            v-model="filters.search"
            classes="has-addons is-rounded"
            placeholder="Search users...">
            <template #right>
              <div class="buttons has-addons is-rounded">
                <submit-button 
                  @submit="runSearch"
                  class="is-rounded">
                  Search
                </submit-button>
                <action-button
                  v-if="$route.query.search"
                  @click="clearFilters">
                  <icon icon="times"/> 
                </action-button>
                <action-button 
                  @click="toggleStatusView"
                  class="button is-rounded">
                  {{ filters.active ? 'Inactive' : 'Active' }} Users
                </action-button>
                <router-link 
                  class="button is-rounded is-flex" 
                  :to="{
                    name: 'create-user'
                  }">
                    <icon icon="plus"/>
                    <span>User</span>
                  </router-link>
              </div>
            </template>
          </text-input>
        </form>
      </column>
    </columns>
    <columns>
      <column>
        <div class="index-rows">
          <div class="box is-marginless" v-for="user in users.data" :key="user.id">
            <columns class="is-mobile">
              <column class="is-half-mobile">
                <router-link class="is-flex is-align-items-center" :to="{
                    name: 'user-manager',
                    params: {
                      user: user.uuid
                    }
                  }">
                  <avatar 
                    class="mr-2"
                    :path="user.profile ? user.profile.picture_url : null"
                    :name="user.full_name"
                    :size="24"
                  />
                  {{ user.full_name }} <icon v-tooltip="'Administrator'" icon="shield" class="has-text-warning" v-if="isAdmin(user.abilities)"/>
                </router-link>
                <small class="has-text-grey">
                  <span v-if="user.designation"> {{ user.designation }}, </span> 
                  {{ user.email }}<span v-if="user.profile && user.profile.mobile">, {{ user.profile.mobile }}</span>
                  <span class="is-small tag is-warning ml-1" v-if="user.is_passive">Passive</span>
                </small>
              </column>
              <column class="is-one-fifth-tablet is-two-quarters-mobile is-flex is-align-items-center is-justify-content-end">
                <p v-if="user.latest_device">{{ user.latest_device.name }} - {{ user.latest_device.version }}</p>
              </column>
              <column class="is-one-fifth-tablet is-two-quarters-mobile is-flex is-align-items-center is-justify-content-end">
                <span class="tag" :class="{
                    'is-danger': !user.active,
                    'is-success': user.active,
                  }">
                  {{ user.active ? 'Active User' : 'Inactive User' }}
                </span>
              </column>
            </columns>
          </div>
        </div>

        <pager v-if="users.data.length" :pageable="users" context="User" jump-controls @nav="goToPage" />

        <no-items-to-display
          :item-count="users.data.length"
          with-link
          heading="There are no users available for display."
          :to="{
            name: 'create-user'
          }"
          to-text="New User"
        />
      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
import { common as backend } from '@/api'
import download from 'downloadjs'

export default {

  data: () => ({
    loading: true,
    filters: {
      search: '',
      active: true
    },
    users: {
      data: []
    }
  }),

  created() {
    this.filters = { ...this.filters, ...this.$route.query }
  },

  beforeMount() {
    this.loadUsers()
  },

  methods: {
    loadUsers() {
      this.filters = { ...this.filters, ...this.$route.query }
      get('v1' + this.$route.path, ({data}) => {
        this.users = data
        this.loading = false
      }, () => this.loading = false, { params: this.filters })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'users',
        query: this.filters
      })
    },
    clearFilters() {
      this.filters.search = ''
      this.$router.push({
        name: 'users',
      })
    },
    isAdmin(abilities) {
      return abilities.filter(permission => permission.name === '*').length
    },
    toggleStatusView() {
      this.filters.active = !this.filters.active
      this.runSearch()
    },
    downloadExcel() {
      this.downloading = true
      backend.downloadFile(`/v1/users/export/xlsx`, ({ data }) => {
        download(data, `users.xlsx`, 'xlsx')
        this.downloading = false
      }, () => {
        this.downloading = false
      }, this.filters)
    },
  },

  watch: {
    '$route': 'loadUsers'
  }

}
</script>